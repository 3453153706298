import { apiCall } from "../APICall";
import * as t from "io-ts";
import { eq } from "fp-ts";
import { constFalse } from "fp-ts/function";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { VerifyLinkError } from "../globalDomain";
import { LoginOutput } from "../Login/api";
import { LocalizedString, NonNegativeInteger } from "design-system";
import { UUID } from "io-ts-types/lib/UUID";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

const RequestChangePasswordInput = t.type({
  email: t.string,
});
type RequestChangePasswordInput = t.TypeOf<typeof RequestChangePasswordInput>;

export const requestChangePassword = apiCall({
  path: ["authorization", "3P", "noauth", "sendResetEmail"],
  inputCodec: RequestChangePasswordInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

const VerifyChangePasswordLinkInput = t.type(
  {
    resetToken: UUID,
  },
  "VerifyChangePasswordLinkInput"
);

const eqVerifyChangePasswordLinkInput = eq.getStructEq({
  resetToken: eq.eqString,
});

export const verifyChangePasswordLink = apiCall({
  path: ["authorization", "3P", "noauth", "resetEmail", "verify"],
  inputCodec: VerifyChangePasswordLinkInput,
  inputEq: eqVerifyChangePasswordLinkInput,
  outputCodec: t.unknown,
  errorCodec: VerifyLinkError,
});

const ResetPasswordInput = t.type({
  password: NonEmptyString,
});

const ChangePasswordInput = t.type({
  ...ResetPasswordInput.props,
  resetToken: UUID,
});
type ChangePasswordInput = t.TypeOf<typeof ChangePasswordInput>;

const ChangePasswordSaveGateErrorCode = t.keyof(
  {
    "SAVEGATE:19011": true, // Password cannot match the previous password(s)
    "SAVEGATE:19108": true, // Wrong new password
    "SAVEGATE:19002": true, // Password is too weak
  },
  "ChangePasswordSaveGateErrorCode"
);
type ChangePasswordSaveGateErrorCode = t.TypeOf<
  typeof ChangePasswordSaveGateErrorCode
>;

const ChangePasswordAPIError = t.type(
  {
    errorCode: ChangePasswordSaveGateErrorCode,
  },
  "ChangePasswordAPIError"
);
export type ChangePasswordAPIError = t.TypeOf<typeof ChangePasswordAPIError>;

export const resetPassword = apiCall({
  path: ["authorization", "3P", "noauth", "resetPassword"],
  inputCodec: ChangePasswordInput,
  inputEq: eq.fromEquals(constFalse),
  errorCodec: ChangePasswordAPIError,
});

export const changePassword = apiCall({
  path: ["authorization", "3P", "changePassword"],
  inputCodec: ResetPasswordInput,
  inputEq: eq.fromEquals(constFalse),
  errorCodec: ChangePasswordAPIError,
});

export const ValidateCredentialsInput = t.type(
  {
    email: t.string,
    password: t.string,
  },
  "ValidateCredentialsInput"
);
export type ValidateCredentialsInput = t.TypeOf<
  typeof ValidateCredentialsInput
>;

export const ValidateCredentialsOutput = t.type(
  {
    flowId: NonEmptyString,
    phoneNumber: LocalizedString,
    expired: optionFromNullable(t.boolean),
  },
  "ValidateCredentialsSuccess"
);
export type ValidateCredentialsOutput = t.TypeOf<
  typeof ValidateCredentialsOutput
>;

const ValidateCredentialsSaveGateErrorCode = t.keyof(
  {
    "SAVEGATE:19012": true, // Broker has no active password.
    "SAVEGATE:19013": true, // Broker is blocked.
    "SAVEGATE:19014": true, // Password of broker has been expired.
    "SAVEGATE:19015": true, // Broker has no active password.
    "eShopFE.3P.Login.wrongFormatNumber": true, // returned by BFF if an invalid phone number is configured for this 3p in Orbit
  },
  "ValidateCredentialsSaveGateErrorCode"
);
type ValidateCredentialsSaveGateErrorCode = t.TypeOf<
  typeof ValidateCredentialsSaveGateErrorCode
>;

const ValidateCredentialsAPIError = t.type(
  {
    errorCode: ValidateCredentialsSaveGateErrorCode,
  },
  "ValidateCredentialsAPIError"
);
export type ValidateCredentialsAPIError = t.TypeOf<
  typeof ValidateCredentialsAPIError
>;

export const validateCredentials = apiCall({
  path: ["authorization", "3P", "noauth", "login"],
  inputCodec: ValidateCredentialsInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ValidateCredentialsOutput,
  errorCodec: ValidateCredentialsAPIError,
});

export const LoginInput = t.type(
  {
    otp: t.string,
    tempFlowId: t.string,
  },
  "LoginInput"
);
export type LoginInput = t.TypeOf<typeof LoginInput>;

const LoginSaveGateErrorCode = t.keyof(
  {
    "SAVEGATE:75008": true, // OTP is expired
    "SAVEGATE:75009": true, // OTP is wrong
  },
  "LoginSaveGateErrorCode"
);
type LoginSaveGateErrorCode = t.TypeOf<typeof LoginSaveGateErrorCode>;

const LoginAPIError = t.type(
  {
    errorCode: LoginSaveGateErrorCode,
    remainingAttempts: NonNegativeInteger,
    remainingRequests: NonNegativeInteger,
  },
  "LoginAPIError"
);
export type LoginAPIError = t.TypeOf<typeof LoginAPIError>;

export const login = apiCall({
  path: ["authorization", "3P", "token"],
  inputCodec: LoginInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: LoginOutput,
  errorCodec: LoginAPIError,
});

const sendOTPInput = t.type(
  {
    tempFlowId: t.string,
  },
  "SendOTPInput"
);

const SendOTPSaveGateErrorCode = t.keyof(
  {
    "SAVEGATE:75016": true, // User is permanently blocked.
    "SAVEGATE:75004": true, // Sending verification code is blocked for a time frame due too many attempts.
    "SAVEGATE:76103": true, // Sending verification code is blocked for the given phone number / email address!
  },
  "SendOTPSaveGateErrorCode"
);

export type SendOTPSaveGateErrorCode = t.TypeOf<
  typeof SendOTPSaveGateErrorCode
>;

const SendOTPAPIOutput = t.type(
  {
    error: optionFromNullable(t.string),
    errorCode: optionFromNullable(SendOTPSaveGateErrorCode),
    remainingAttempts: NonNegativeInteger,
    remainingRequests: NonNegativeInteger,
    success: t.boolean,
  },
  "SendOTPAPIOutput"
);
export type SendOTPAPIOutput = t.TypeOf<typeof SendOTPAPIOutput>;

export const sendOTP = apiCall({
  path: ["authorization", "3P", "noauth", "sendOTP"],
  inputCodec: sendOTPInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: SendOTPAPIOutput,
});
