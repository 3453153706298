import { option, taskEither } from "fp-ts";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import {
  Box,
  unsafePositiveInteger,
  LocalizedString,
  NonNegativeInteger,
} from "design-system";
import { GenericError } from "../globalDomain";
import * as otpAPI from "../OTP/domain";
import { useFormatMessage } from "../intl";
import { BackButton } from "../Common/BackButton/BackButton";
import { OTPAuthorization } from "../OTPAuthorization/OTPAuthorization";
import { ApplicationLimitError } from "../MortgageDashboard/domain";
import { Option } from "fp-ts/Option";

type Props = {
  phoneNumber: LocalizedString;
  onGenerateOTP: ReaderTaskEither<
    otpAPI.OtpGenerationInput,
    otpAPI.OtpGenerationError | GenericError | ApplicationLimitError,
    otpAPI.OtpGenerationOutput & { transactionId: Option<LocalizedString> }
  >;
  onVerifyOTP: ReaderTaskEither<
    otpAPI.OtpVerifyInput,
    otpAPI.OtpVerifyError | GenericError,
    unknown
  >;
  onFailure: () => unknown;
  backButtonAction: () => unknown;
  transactionId: Option<LocalizedString>;
  remainingOtpRequests: NonNegativeInteger;
};

export function LoginOTP(props: Props) {
  const formatMessage = useFormatMessage();

  return (
    <Box column grow>
      <OTPAuthorization
        phoneNumber={props.phoneNumber}
        requestNotice={option.none}
        onSuccess={taskEither.of({})}
        onFailure={props.onFailure}
        otpRequestButtonLabel={formatMessage("Identification.otp.submitValue")}
        otpSubmitButtonLabel={formatMessage("3PLogin.LoginOTP.verifyLabel")}
        otpTitleLabel={formatMessage(
          "Identification.otp.authorizeWithSMS.title"
        )}
        onProcessStart={taskEither.of({})}
        onGenerateOTP={props.onGenerateOTP}
        onVerifyOTP={props.onVerifyOTP}
        length={unsafePositiveInteger(6)}
        allowResendOTP
        afterLoginState={{
          type: "insertingOtp",
          transactionId: props.transactionId,
          remainingOtpRequests: props.remainingOtpRequests,
          pending: false,
        }}
      />
      <Box grow column hAlignContent="left">
        <BackButton action={props.backButtonAction} />
      </Box>
    </Box>
  );
}
