import { useState } from "react";
import {
  AuthenticationInfo,
  Box,
  FeedbackDialog,
  Space,
  useAuthenticationContext,
  LocalizedString,
  NonNegativeInteger,
} from "design-system";
import { constant, constNull, constTrue, identity, pipe } from "fp-ts/function";
import { boolean, either, option, taskEither } from "fp-ts";
import { LoginLanding } from "../Login/LoginLanding";
import { LoginOTP } from "./LoginOTP";
import * as thirdPartyAPI from "./api";
import { useCommand } from "../useAPI";
import { RequestChangePassword } from "./RequestChangePassword";
import { LoginLandingWrapper } from "../Login/LoginLandingWrapper";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { IdleLogout } from "../Login/IdleLogout";
import { useFormatMessage } from "../intl";
import { ChangePassword } from "./ChangePassword";
import { Either } from "fp-ts/Either";
import { AuthenticationContextProvider } from "../AuthenticationContext";
import {
  parse3PLoginOTPError,
  parse3PValidateCredentialsError,
  SendOTPError,
  ValidateCredentialsError,
} from "./domain";
import { LocaleSelector } from "../Login/LocaleSelector";
import * as otpAPI from "../OTP/domain";
import { genericError, GenericError } from "../globalDomain";
import { TaskEither } from "fp-ts/TaskEither";
import { SendOTPAPIOutput, ValidateCredentialsOutput } from "./api";
import {
  LoginFormError,
  loginFormError,
  loginFormWarning,
} from "../Login/Login";

type Props = {
  showPasswordWasResetMessage: boolean;
  render: (passwordWasChanged: boolean) => JSX.Element;
};

type ThirdPartyAuthState =
  | {
      type: "login";
    }
  | {
      type: "otp";
      tempFlowId: NonEmptyString;
      phoneNumber: LocalizedString;
      passwordExpired: Either<boolean, AuthenticationInfo>;
      remainingOtpRequests: NonNegativeInteger;
    }
  | { type: "requestSetup" }
  | { type: "setup"; authInfo: AuthenticationInfo; passwordExpired: boolean }
  | { type: "passwordWasChanged" };

const initialState: ThirdPartyAuthState = {
  type: "login",
};

export function ThirdPartyAuth(props: Props) {
  const { authInfo, login } = useAuthenticationContext();
  const [
    thirdPartyAuthState,
    setThirdPartyAuthState,
  ] = useState<ThirdPartyAuthState>(initialState);
  const validateCredentials = useCommand(thirdPartyAPI.validateCredentials);
  const verifyOTPAndLogin = useCommand(thirdPartyAPI.login);
  const sendOTP = useCommand(thirdPartyAPI.sendOTP);
  const formatMessage = useFormatMessage();

  function loginSuccess(
    authInfo: AuthenticationInfo,
    passwordWasChanged: boolean
  ) {
    login(authInfo);
    // and reset internal status to the initial one
    setThirdPartyAuthState(
      passwordWasChanged ? { type: "passwordWasChanged" } : initialState
    );
  }

  function requestPasswordSetup() {
    setThirdPartyAuthState({
      type: "requestSetup",
    });
  }

  const decodeSendOTPError = (
    result: SendOTPAPIOutput,
    validationResults: ValidateCredentialsOutput
  ): TaskEither<
    otpAPI.OtpGenerationError | GenericError | SendOTPError,
    ValidateCredentialsOutput & { remainingOtpRequests: NonNegativeInteger }
  > =>
    pipe(
      result.errorCode,
      option.map(value => {
        switch (value) {
          case "SAVEGATE:75004":
            return taskEither.left<SendOTPError>({
              id: "TooManyAttempts",
            });
          case "SAVEGATE:76103":
            return taskEither.left<otpAPI.OtpGenerationError>({
              id: "InvalidFlowId",
            });
          case "SAVEGATE:75016":
            return taskEither.left<SendOTPError>({
              id: "UserBlocked",
            });
        }
      }),
      option.getOrElse(() =>
        taskEither.right({
          ...validationResults,
          remainingOtpRequests: result.remainingRequests,
        })
      )
    );

  const requestOTP = (
    validationResults: ValidateCredentialsOutput
  ): TaskEither<
    | otpAPI.OtpGenerationError
    | SendOTPError
    | GenericError
    | ValidateCredentialsError,
    ValidateCredentialsOutput & { remainingOtpRequests: NonNegativeInteger }
  > =>
    pipe(
      sendOTP({ tempFlowId: validationResults.flowId }),
      taskEither.mapLeft(() => genericError),
      taskEither.chain(result => decodeSendOTPError(result, validationResults))
    );

  return pipe(
    authInfo,
    option.fold(
      (): JSX.Element => {
        switch (thirdPartyAuthState.type) {
          case "passwordWasChanged":
          case "login":
            return (
              <LoginLanding
                successMessage={pipe(
                  props.showPasswordWasResetMessage,
                  boolean.fold(
                    () => option.none,
                    () =>
                      option.some(
                        formatMessage(
                          "3PLogin.passwordSuccessfullyCreatedMessage"
                        )
                      )
                  )
                )}
                onLogin={credentials =>
                  pipe(
                    validateCredentials({
                      email: credentials.username,
                      password: credentials.password,
                    }),
                    taskEither.mapLeft(parse3PValidateCredentialsError),
                    taskEither.chain(requestOTP),
                    taskEither.mapLeft(
                      ({ id }): LoginFormError => {
                        switch (id) {
                          case "GenericError":
                            return loginFormError(
                              "Login.invalidCredentialsError"
                            );
                          case "PasswordNotSetup":
                            return loginFormWarning(
                              "3PLogin.setupPasswordWarning",
                              {
                                label: "3PLogin.setupPasswordActionLabel",
                                action: requestPasswordSetup,
                              }
                            );
                          case "UserBlockedOrPasswordExpired":
                            return loginFormError(
                              "3PLogin.mustResetPasswordError",
                              {
                                label: "3PLogin.setupPasswordActionLabel",
                                action: requestPasswordSetup,
                              }
                            );
                          case "InvalidOrMissingPhoneNumber":
                          case "NoValidPhoneNumber":
                            return loginFormError(
                              "3PLogin.invalidPhoneNumberError"
                            );
                          case "MaxOtpRequestsReached":
                          case "TooManyAttempts":
                            return loginFormError(
                              "3PLogin.tooManyAttemptsError"
                            );
                          case "UserBlocked":
                            return loginFormError(
                              "3PLogin.mustResetPasswordError",
                              {
                                label: "3PLogin.setupPasswordActionLabel",
                                action: requestPasswordSetup,
                              }
                            );
                          case "InvalidFlowId":
                            return loginFormError("GenericError");
                        }
                      }
                    ),
                    taskEither.chain(res =>
                      taskEither.fromIO(() =>
                        setThirdPartyAuthState({
                          type: "otp",
                          tempFlowId: res.flowId,
                          remainingOtpRequests: res.remainingOtpRequests,
                          phoneNumber: res.phoneNumber,
                          passwordExpired: pipe(
                            res.expired,
                            option.fold(
                              () => either.left(false),
                              expired => either.left(expired)
                            )
                          ),
                        })
                      )
                    )
                  )
                }
                onThirdPartyPasswordSetup={requestPasswordSetup}
              />
            );
          case "otp":
            return (
              <LoginLandingWrapper>
                <Box column grow>
                  <Box hAlignContent="right">
                    <LocaleSelector width="150px" />
                  </Box>
                  <Space units={20} />
                  <LoginOTP
                    transactionId={option.none}
                    remainingOtpRequests={
                      thirdPartyAuthState.remainingOtpRequests
                    }
                    phoneNumber={thirdPartyAuthState.phoneNumber}
                    onGenerateOTP={(_: otpAPI.OtpGenerationInput) =>
                      pipe(
                        sendOTP({ tempFlowId: thirdPartyAuthState.tempFlowId }),
                        taskEither.mapLeft(constant(genericError)),
                        taskEither.chain(output =>
                          output.success
                            ? taskEither.right({
                                remainingRequests: output.remainingRequests,
                                transactionId: option.none,
                              })
                            : taskEither.left<
                                otpAPI.OtpGenerationError | GenericError
                              >({
                                id: "MaxOtpRequestsReached",
                              })
                        )
                      )
                    }
                    onVerifyOTP={({ otp }) =>
                      pipe(
                        verifyOTPAndLogin({
                          otp,
                          tempFlowId: thirdPartyAuthState.tempFlowId,
                        }),
                        taskEither.mapLeft(parse3PLoginOTPError),
                        taskEither.chain(authInfo =>
                          taskEither.fromIO(() => {
                            const isPasswordExpired = pipe(
                              thirdPartyAuthState.passwordExpired,
                              either.fold(identity, constTrue)
                            );
                            if (isPasswordExpired) {
                              // require to reset the password before displaying the logged in UI
                              setThirdPartyAuthState({
                                ...thirdPartyAuthState,
                                passwordExpired: either.right(authInfo),
                              });
                            } else {
                              loginSuccess(authInfo, false);
                            }
                          })
                        )
                      )
                    }
                    backButtonAction={() =>
                      setThirdPartyAuthState({ type: "login" })
                    }
                    onFailure={() => {
                      setThirdPartyAuthState({
                        type: "login",
                      });
                    }}
                  />
                  {pipe(
                    thirdPartyAuthState.passwordExpired,
                    either.fold(constNull, authInfo => (
                      <FeedbackDialog
                        type="warning"
                        title={formatMessage(
                          "3PLogin.passwordExpiredModalTitle"
                        )}
                        cta={{
                          label: formatMessage(
                            "3PLogin.passwordExpiredModalActionLabel"
                          ),
                          action: () =>
                            setThirdPartyAuthState({
                              type: "setup",
                              authInfo,
                              passwordExpired: true,
                            }),
                        }}
                      />
                    ))
                  )}
                </Box>
              </LoginLandingWrapper>
            );
          case "requestSetup":
            return (
              <LoginLandingWrapper>
                <RequestChangePassword
                  onBack={() => setThirdPartyAuthState(initialState)}
                />
              </LoginLandingWrapper>
            );
          case "setup":
            return (
              <AuthenticationContextProvider
                authInfo={option.some(thirdPartyAuthState.authInfo)}
                potentialClientToken={taskEither.of(option.none)}
                isChangingPassword
              >
                <LoginLandingWrapper>
                  <ChangePassword
                    resetToken={option.none}
                    onPasswordChanged={() =>
                      loginSuccess(thirdPartyAuthState.authInfo, true)
                    }
                  />
                </LoginLandingWrapper>
              </AuthenticationContextProvider>
            );
        }
      },
      () => (
        <IdleLogout logoutHandler={option.none}>
          {props.render(thirdPartyAuthState.type === "passwordWasChanged")}
        </IdleLogout>
      )
    )
  );
}
