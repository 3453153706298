import { useState } from "react";
import {
  Box,
  Form,
  FormRow,
  TextField,
  FormSection,
  FeedbackDialog,
  Card,
  AngleLeftIcon,
  Space,
  useForm,
  validators,
} from "design-system";

import { useFormatMessage } from "../intl";
import { option, taskEither } from "fp-ts";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { useValidators } from "../Common/useValidators";
import { useCommand } from "../useAPI";
import * as api from "./api";
import { pipe } from "fp-ts/function";
import { LocaleSelector } from "../Login/LocaleSelector";

type Props = {
  onBack: () => unknown;
};

function RequestChangePasswordForm(props: {
  onSend: (email: NonEmptyString) => unknown;
  onBack: () => unknown;
}) {
  const formatMessage = useFormatMessage();
  const requestChangePassword = useCommand(api.requestChangePassword);

  const onSubmit = ({ email }: { email: NonEmptyString }) =>
    pipe(
      requestChangePassword({ email }),
      taskEither.bimap(
        () => [formatMessage("GenericError")],
        () => email
      )
    );

  const { nonEmptyString, validEmail } = useValidators();

  const { fieldProps, handleSubmit } = useForm(
    {
      initialValues: {
        email: "",
      },
      fieldValidators: () => ({
        email: validators.inSequence(nonEmptyString, validEmail),
      }),
    },
    {
      onSubmit: ({ email }) =>
        pipe(
          onSubmit({ email }),
          taskEither.chain(() => taskEither.fromIO(() => props.onSend(email)))
        ),
    }
  );

  return (
    <Box column width="100%">
      <Box hAlignContent="right">
        <LocaleSelector width="150px" />
      </Box>
      <Space units={20} />
      <Box column grow shrink hAlignContent="center" vAlignContent="center">
        <Box column shrink width={400}>
          <Card>
            <Form
              grow
              shrink
              cancelButton={{
                action: props.onBack,
                label: formatMessage("3PLogin.resetPassword.backToLogin"),
                icon: AngleLeftIcon,
              }}
              submitButton={{
                action: handleSubmit,
                label: formatMessage("3PLogin.sendSetPasswordLink"),
              }}
            >
              <FormSection
                errors={option.none}
                heading={{
                  title: formatMessage("3PLogin.resetPassword.title"),
                  subtitle: formatMessage("3PLogin.resetPassword.description"),
                }}
              >
                <FormRow type="full">
                  <TextField
                    {...fieldProps("email")}
                    label={formatMessage("3PLogin.resetPassword.email")}
                    placeholder={formatMessage(
                      "3PLogin.resetPassword.emailPlaceholder"
                    )}
                  />
                </FormRow>
              </FormSection>
            </Form>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}

function EmailSent(props: { email: NonEmptyString; onBack: () => unknown }) {
  const formatMessage = useFormatMessage();

  return (
    <FeedbackDialog
      type="success"
      title={formatMessage("3PLogin.resetPassword.emailSent", {
        email: props.email,
      })}
      cta={{
        label: formatMessage("3PLogin.resetPassword.backToLogin"),
        action: props.onBack,
      }}
    />
  );
}

export function RequestChangePassword(props: Props) {
  type View =
    | { id: "InsertEmail" }
    | { id: "EmailSent"; email: NonEmptyString };

  const [view, setView] = useState<View>({ id: "InsertEmail" });

  switch (view.id) {
    case "InsertEmail":
      return (
        <RequestChangePasswordForm
          onSend={email => setView({ id: "EmailSent", email })}
          onBack={props.onBack}
        />
      );
    case "EmailSent":
      return <EmailSent onBack={props.onBack} email={view.email} />;
  }
}
