import { genericError, GenericError, NonNegative } from "../globalDomain";
import {
  ChangePasswordAPIError,
  LoginAPIError,
  ValidateCredentialsAPIError,
} from "./api";

type ValidateCredentialsErrorCode =
  | "UserBlockedOrPasswordExpired"
  | "PasswordNotSetup"
  | "InvalidOrMissingPhoneNumber";
export type ValidateCredentialsError = { id: ValidateCredentialsErrorCode };

export function parse3PValidateCredentialsError(
  apiError: GenericError | ValidateCredentialsAPIError
): GenericError | ValidateCredentialsError {
  if ("errorCode" in apiError) {
    switch (apiError.errorCode) {
      case "SAVEGATE:19013":
      case "SAVEGATE:19014":
        return { id: "UserBlockedOrPasswordExpired" };
      case "SAVEGATE:19012":
      case "SAVEGATE:19015":
        return { id: "PasswordNotSetup" };
      case "eShopFE.3P.Login.wrongFormatNumber":
        return { id: "InvalidOrMissingPhoneNumber" };
    }
  } else return genericError;
}

type SendOTPErrorCode =
  | "NoValidPhoneNumber"
  | "UserBlocked"
  | "TooManyAttempts";
export type SendOTPError = { id: SendOTPErrorCode };

type ChangeOrResetPasswordErrorCode = "ReusedPassword" | "InvalidPassword";
export type ChangeOrResetPasswordError = { id: ChangeOrResetPasswordErrorCode };

export function parse3PChangeOrResetPasswordError(
  apiError: GenericError | ChangePasswordAPIError
): GenericError | ChangeOrResetPasswordError {
  if ("errorCode" in apiError) {
    switch (apiError.errorCode) {
      case "SAVEGATE:19002":
      case "SAVEGATE:19108":
        return { id: "InvalidPassword" };
      case "SAVEGATE:19011":
        return { id: "ReusedPassword" };
    }
  } else return genericError;
}

type LoginOTPErrorCode = "OTPExpired";
export type LoginOTPError =
  | { id: LoginOTPErrorCode }
  | { id: "InvalidOTP"; attemptsLeft: NonNegative };

export function parse3PLoginOTPError(
  apiError: GenericError | LoginAPIError
): GenericError | LoginOTPError {
  if ("errorCode" in apiError) {
    switch (apiError.errorCode) {
      case "SAVEGATE:75008":
        return { id: "OTPExpired" };
      case "SAVEGATE:75009":
        return {
          id: "InvalidOTP",
          attemptsLeft: apiError.remainingAttempts,
        };
    }
  } else return genericError;
}
