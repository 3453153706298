import {
  Box,
  Form,
  FormRow,
  FormSection,
  PasswordField,
  PasswordFieldWithCriteria,
  PasswordStrength,
  Card,
  LocalizedString,
  useForm,
  TextChildren,
  passwordStrengthCalculator,
} from "design-system";
import { useFormatMessage } from "../intl";
import { option, record, taskEither } from "fp-ts";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { TaskEither } from "fp-ts/TaskEither";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { pipe } from "fp-ts/function";
import { useCommand } from "../useAPI";
import * as api from "./api";
import { UUID } from "io-ts-types/lib/UUID";
import { Option } from "fp-ts/Option";
import { useCriteria } from "../Common/useCriteria";
import { useValidators } from "../Common/useValidators";
import { GenericError } from "../globalDomain";
import {
  ChangeOrResetPasswordError,
  parse3PChangeOrResetPasswordError,
} from "./domain";

type Props = {
  resetToken: Option<UUID>;
  onPasswordChanged: () => unknown;
};

export function ChangePassword(props: Props) {
  const formatMessage = useFormatMessage();
  const resetPassword = useCommand(api.resetPassword);
  const changePassword = useCommand(api.changePassword);
  const { thirdPartyPasswordCriteria, passwordStrengthLabel } = useCriteria();
  const { validPassword, passwordMatches } = useValidators();

  const formatErrors = (
    error: GenericError | ChangeOrResetPasswordError
  ): NonEmptyArray<LocalizedString> => {
    switch (error.id) {
      case "GenericError":
        return [formatMessage("GenericError")];
      case "InvalidPassword":
        return [formatMessage("3PLogin.resetPassword.invalidPasswordError")];
      case "ReusedPassword":
        return [formatMessage("3PLogin.resetPassword.passwordReusedError")];
    }
  };

  const onSubmit = ({
    password,
  }: {
    password: NonEmptyString;
  }): TaskEither<NonEmptyArray<TextChildren>, unknown> =>
    pipe(
      props.resetToken,
      option.fold(
        () => changePassword({ password }),
        resetToken => resetPassword({ password, resetToken })
      ),
      taskEither.mapLeft(parse3PChangeOrResetPasswordError),
      taskEither.mapLeft(formatErrors),
      taskEither.chain(() => taskEither.fromIO(props.onPasswordChanged))
    );

  const { formErrors, values, fieldProps, handleSubmit, setValues } = useForm(
    {
      initialValues: {
        password: { value: "", strength: "weak" as PasswordStrength },
        confirmPassword: "",
      },
      fieldValidators: ({ password }) => ({
        password: validPassword,
        confirmPassword: passwordMatches(password),
      }),
    },
    {
      onSubmit: ({ password }) => onSubmit({ password }),
    }
  );

  return (
    <Box grow shrink hAlignContent="center" vAlignContent="center">
      <Box column shrink width={400}>
        <Card>
          <Form
            grow
            shrink
            submitButton={{
              action: handleSubmit,
              label: formatMessage("3PLogin.resetPassword.createPassword"),
            }}
          >
            <FormSection
              errors={formErrors}
              heading={{
                title: formatMessage(
                  "3PLogin.resetPassword.setNewPasswordTitle"
                ),
              }}
            >
              <FormRow type="full">
                <PasswordFieldWithCriteria
                  {...fieldProps("password")}
                  onChange={password =>
                    setValues({
                      password,
                      // re-trigger validation of confirmPassword
                      confirmPassword: values.confirmPassword,
                    })
                  }
                  showHideButton={option.some({
                    showLabel: formatMessage("Show"),
                    hideLabel: formatMessage("Hide"),
                  })}
                  label={formatMessage("3PLogin.resetPassword.password")}
                  placeholder={formatMessage(
                    "3PLogin.resetPassword.passwordPlaceholder"
                  )}
                  criteria={thirdPartyPasswordCriteria}
                  strength={passwordStrengthCalculator({
                    baseCriteria: ["minLength"],
                    goodThreshold: 3,
                    strongThreshold:
                      pipe(
                        thirdPartyPasswordCriteria,
                        record.keys,
                        a => a.length
                      ) - 1,
                  })}
                  strengthLabel={passwordStrengthLabel}
                />
              </FormRow>
              <FormRow type="full">
                <PasswordField
                  {...fieldProps("confirmPassword")}
                  showHideButton={option.some({
                    showLabel: formatMessage("Show"),
                    hideLabel: formatMessage("Hide"),
                  })}
                  label={formatMessage("3PLogin.resetPassword.confirmPassword")}
                  placeholder={formatMessage(
                    "3PLogin.resetPassword.confirmPasswordPlaceholder"
                  )}
                />
              </FormRow>
            </FormSection>
          </Form>
        </Card>
      </Box>
    </Box>
  );
}
