import { Eq } from "fp-ts/Eq";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import {
  APIParameters,
  genericError,
  GenericError,
  PortalBlocked,
  portalBlocked,
  UnauthorizedError,
  unauthorizedError,
} from "./globalDomain";
import { Option } from "fp-ts/Option";
import { Type } from "io-ts";
import { pipe, constVoid, identity, constant } from "fp-ts/function";
import { nonEmptyArray, taskEither, either, option, task, record } from "fp-ts";
import { TaskEither } from "fp-ts/TaskEither";
import { Either } from "fp-ts/Either";
import * as t from "io-ts";
import { UUID } from "io-ts-types/lib/UUID";
import { IO } from "fp-ts/IO";
import { sequenceS } from "fp-ts/Apply";
import { failure } from "io-ts/lib/PathReporter";
import { TokenInfo, LocalizedString, AuthenticationInfo } from "design-system";
import { getFingerPrint } from "./fingerprint";
import { translationsActive } from "./intl";

export type Path = nonEmptyArray.NonEmptyArray<string>;

type RefreshingTokenStatus = "Ready" | "RefreshingToken";

type FetchConfig = {
  apiEndpoint: NonEmptyString;
  url: Path;
  body: unknown;
  bodyType: "JSON" | "Binary";
  apiParameters: APIParameters;
  tokenInfo: Option<TokenInfo>;
  flowId: Option<UUID>;
};

type APIResponse = {
  body: unknown;
  status: number;
};

type APIResponseAndNewToken = TaskEither<
  unknown,
  {
    response: APIResponse;
    newTokenInfo: Option<TokenInfo>;
  }
>;

const refreshStatus: {
  refreshed: Promise<unknown>;
  signalRefreshed: () => unknown;
  status: RefreshingTokenStatus;
  latestTokenInfo: Option<TokenInfo>;
} = {
  refreshed: task.never(),
  signalRefreshed: constVoid,
  latestTokenInfo: option.none,
  status: "Ready",
};

function refreshStart(): IO<void> {
  return () => {
    refreshStatus.refreshed = new Promise(resolve => {
      refreshStatus.signalRefreshed = resolve as any;
    });
    refreshStatus.status = "RefreshingToken";
  };
}

function refreshEnd(latestTokenInfo: TokenInfo): IO<void> {
  return () => {
    refreshStatus.refreshed = task.never();
    refreshStatus.latestTokenInfo = option.some(latestTokenInfo);
    refreshStatus.status = "Ready";
    const signalRefreshed = refreshStatus.signalRefreshed;
    refreshStatus.signalRefreshed = constVoid;
    signalRefreshed();
  };
}

export function setLatestTokenInfo(tokenInfo: Option<TokenInfo>) {
  refreshStatus.latestTokenInfo = tokenInfo;
}

export function resetRefreshStatus() {
  refreshStatus.refreshed = task.never();
  refreshStatus.signalRefreshed = constVoid;
  setLatestTokenInfo(option.none);
  refreshStatus.status = "Ready";
}

function loggedOut(): IO<void> {
  return () => {
    resetRefreshStatus();
    window.location.reload();
  };
}

function encodeFormData(body: unknown): Either<unknown, FormData> {
  if (typeof body !== "object") return either.left("InvalidFormData");

  const formData = new FormData();
  pipe(
    body || {},
    record.mapWithIndex((key, value: unknown) => {
      formData.append(
        key,
        value instanceof Blob ? value : JSON.stringify(value)
      );
    })
  );

  return either.right(formData);
}

function encodeBody(
  body: unknown,
  bodyType: "JSON" | "Binary"
): Either<unknown, string | FormData | null> {
  switch (bodyType) {
    case "JSON":
      return pipe(
        either.stringifyJSON(body, identity),
        // null/undefined values are not valid bodies for Prism
        either.map(body => (body == null ? "{}" : body))
      );
    case "Binary":
      return encodeFormData(body);
  }
}

function fetchAPIInternal(
  config: FetchConfig
): TaskEither<unknown, APIResponse> {
  const headers = new Headers([
    ["Tenant", config.apiParameters.tenant],
    ["Channel", config.apiParameters.channel],
    ["Accept-Language", config.apiParameters.language],
    ["Translated", String(translationsActive)],
  ]);

  const fingerPrint = getFingerPrint(config.apiEndpoint);
  if (fingerPrint != null) {
    headers.append("Fingerprint", String(fingerPrint));
  }

  if (config.bodyType !== "Binary") {
    headers.append("Content-Type", "application/json");
  }

  if (option.isSome(config.flowId)) {
    headers.append("FlowId", config.flowId.value);
  }

  if (option.isSome(config.tokenInfo)) {
    headers.append("Authorization", `Bearer ${config.tokenInfo.value.token}`);
  }

  return pipe(
    encodeBody(config.body, config.bodyType),
    taskEither.fromEither,
    taskEither.chain(body =>
      taskEither.tryCatch(
        () =>
          fetch(
            [config.apiEndpoint, ...config.url].map(s => s.trim()).join("/"),
            {
              method: "POST",
              cache: "no-cache",
              headers,
              body,
            }
          ).then(res =>
            res.json().then(
              body => ({
                body: body,
                status: res.status,
              }),
              () => ({ body: null, status: res.status })
            )
          ),
        identity
      )
    )
  );
}

function refresh(config: FetchConfig): TaskEither<unknown, TokenInfo> {
  return pipe(
    config,
    withLatestToken,
    config => config.tokenInfo,
    option.fold(
      () => taskEither.left(unauthorizedError),
      tokenInfo =>
        fetchAPIInternal({
          apiParameters: config.apiParameters,
          apiEndpoint: config.apiEndpoint,
          url: ["authorization", "token", "refresh"],
          bodyType: "JSON",
          body: {
            token: tokenInfo.token,
            refreshToken: tokenInfo.refreshToken,
          }, //'flowID' was being sent from tokenInfo in body as well
          tokenInfo: option.none,
          flowId: option.none,
        })
    ),
    taskEither.map(res => res.body),
    taskEither.chainEitherKW(TokenInfo.decode),
    taskEither.mapLeft(constant(unauthorizedError))
  );
}

function refreshAndRetry(
  config: FetchConfig,
  wasWaiting = false
): APIResponseAndNewToken {
  if (refreshStatus.status === "RefreshingToken") {
    return pipe(
      () => refreshStatus.refreshed,
      taskEither.fromTask,
      taskEither.chain(() => refreshAndRetry(config, true))
    );
  } else if (wasWaiting) {
    return pipe(
      config,
      withLatestToken,
      fetchAPIInternal,
      taskEither.map(response => ({
        newTokenInfo: option.none,
        response,
      }))
    );
  } else {
    return taskEither.bracket(
      taskEither.fromIO(refreshStart()),
      () =>
        pipe(
          refresh(config),
          taskEither.chain(newTokenInfo =>
            pipe(
              fetchAPIInternal({
                ...config,
                tokenInfo: option.some(newTokenInfo),
              }),
              taskEither.map(response => ({
                newTokenInfo: option.some(newTokenInfo),
                response,
              }))
            )
          )
        ),
      (_, refreshResult) =>
        taskEither.fromIO(
          pipe(
            refreshResult,
            option.fromEither,
            option.chain(({ newTokenInfo }) => newTokenInfo),
            option.fold(loggedOut, refreshEnd)
          )
        )
    );
  }
}

function withLatestToken(config: FetchConfig): FetchConfig {
  return pipe(
    sequenceS(option.option)({
      latestTokenInfo: refreshStatus.latestTokenInfo,
      configTokenInfo: config.tokenInfo,
    }),
    option.map(({ latestTokenInfo }) => ({
      ...config,
      tokenInfo: option.some(latestTokenInfo),
    })),
    option.getOrElse(constant(config))
  );
}

export function fetchAPI(config: FetchConfig): APIResponseAndNewToken {
  return pipe(
    config,
    withLatestToken,
    fetchAPIInternal,
    taskEither.chain(response => {
      if (response.status === 307) {
        return taskEither.left(portalBlocked);
      } else if (response.status !== 401 || option.isNone(config.tokenInfo)) {
        return taskEither.of({ response, newTokenInfo: option.none });
      } else {
        return refreshAndRetry(config);
      }
    })
  );
}

type APICallContext = {
  apiEndpoint: NonEmptyString;
  tokenInfo: Option<TokenInfo>;
  flowId: Option<UUID>;
  apiParameters: APIParameters;
};

type APICallPathFn<I> = (input: I) => Path;
type APICallBodyFn<I, B> = (input: I) => B;

type APICallDefinitionOB<I, O, OO, B, BB, E, EE> = {
  inputEq: Eq<I>;
  bodyCodec: Type<B, BB>;
  outputCodec?: Type<O, OO>;
  errorCodec?: Type<E, EE>;
  path: APICallPathFn<I>;
  body: APICallBodyFn<I, B>;
  bodyType?: "JSON" | "Binary";
};
type APICallDefinitionO<I, II, O, OO, E, EE> = {
  inputEq: Eq<I>;
  inputCodec: Type<I, II>;
  outputCodec?: Type<O, OO>;
  errorCodec?: Type<E, EE>;
  path: Path;
  bodyType?: "JSON" | "Binary";
};

type APICallDefinition<I, II, O, OO, B, BB, E, EE> =
  | APICallDefinitionO<I, II, O, OO, E, EE>
  | APICallDefinitionOB<I, O, OO, B, BB, E, EE>;

type APICallContextAndInput<I> = APICallContext & { input: I };

export type APICallOutput<O> = { output: O; newTokenInfo: Option<TokenInfo> };

export type APICallImplementation<I, E, O> = {
  inputEq: Eq<I>;
  fetch: ReaderTaskEither<
    APICallContextAndInput<I>,
    ErrorBody<E>,
    APICallOutput<O>
  >;
};

function decodeSuccessBody<O, OO>(
  body: unknown,
  codec: Type<O, OO>
): Either<GenericError, O> {
  return pipe(
    body,
    codec.decode,
    either.mapLeft(e => {
      console.error("Decoding error: ", failure(e).join("\n"));
      return genericError;
    })
  );
}

type ErrorBody<E> =
  | E
  | GenericError
  | UnauthorizedError
  | PortalBlocked
  | LocalizedString;

function decodeErrorBody<E, EE>(
  body: unknown,
  codec?: Type<E, EE>
): ErrorBody<E> {
  if (codec) {
    return pipe(
      body,
      codec.decode,
      either.orElse(() =>
        pipe(
          body,
          UnauthorizedError.decode,
          either.map<ErrorBody<E>, ErrorBody<E>>(identity)
        )
      ),
      either.orElse(() =>
        pipe(
          body,
          PortalBlocked.decode,
          either.map<ErrorBody<E>, ErrorBody<E>>(identity)
        )
      ),
      either.orElse(() =>
        pipe(
          body,
          LocalizedString.decode,
          either.map<ErrorBody<E>, ErrorBody<E>>(identity)
        )
      ),
      either.getOrElseW(constant(genericError))
    );
  } else {
    return pipe(
      body,
      UnauthorizedError.decode,
      either.orElse(() =>
        pipe(
          body,
          PortalBlocked.decode,
          either.map<ErrorBody<E>, ErrorBody<E>>(identity)
        )
      ),
      either.orElse(() =>
        pipe(
          body,
          LocalizedString.decode,
          either.map<ErrorBody<E>, ErrorBody<E>>(identity)
        )
      ),
      either.getOrElseW(constant(genericError))
    );
  }
}

// TODO(gio): temporary hack, flowId will soon be passed via headers like token is
function withFlowId(flowId: Option<UUID>, body: unknown): unknown {
  if (option.isSome(flowId) && body != null && typeof body === "object") {
    return {
      flowId: flowId.value,
      ...body,
    };
  } else if (option.isSome(flowId) && body == null) {
    return { flowId: flowId.value };
  } else {
    return body;
  }
}

export function apiCall<I, II, O, OO, B, BB, E, EE>(
  config: APICallDefinition<I, II, O, OO, B, BB, E, EE>
): APICallImplementation<I, ErrorBody<E>, O> {
  const body: APICallBodyFn<I, B> =
    "body" in config ? config.body : (identity as any);
  const path: APICallPathFn<I> =
    typeof config.path === "function" ? config.path : constant(config.path);
  const bodyCodec: Type<B> =
    "bodyCodec" in config ? config.bodyCodec : (config.inputCodec as any);
  const outputCodec: Type<O> =
    "outputCodec" in config ? config.outputCodec : (t.unknown as any);
  return {
    inputEq: config.inputEq,
    fetch: ({ apiEndpoint, apiParameters, tokenInfo, flowId, input }) =>
      pipe(
        fetchAPI({
          apiParameters,
          body: withFlowId(flowId, bodyCodec.encode(body(input))),
          bodyType: config.bodyType || "JSON",
          tokenInfo,
          apiEndpoint,
          url: path(input),
          flowId: flowId,
        }),
        taskEither.mapLeft(body => decodeErrorBody(body, config.errorCodec)), // network error or unauthorized
        taskEither.chainEitherKW(
          ({ response: { body, status }, newTokenInfo }) => {
            if (status === 200) {
              return pipe(
                decodeSuccessBody(body, outputCodec),
                either.map(output => ({ output, newTokenInfo }))
              );
            } else {
              return either.left(decodeErrorBody(body, config.errorCodec));
            }
          }
        )
      ),
  };
}

export function getLatestToken(
  authInfo: Option<AuthenticationInfo>
): Option<NonEmptyString> {
  if (option.isSome(refreshStatus.latestTokenInfo)) {
    return option.some(refreshStatus.latestTokenInfo.value.token);
  } else if (option.isSome(authInfo)) {
    return option.some(authInfo.value.token);
  }
  return option.none;
}
